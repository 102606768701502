import Block from '../../common.blocks/block'

export default class Header extends Block {
	static title = 'header'

	constructor($el) {
		super($el)

		this.fixedHeader = this.$el.findElement('fixed')

		if (this.$el.hasModifier('transparent')) {
			document.addEventListener('scroll', () => this.toggleTransparent())
		}
	}

	toggleTransparent() {
		if (window.scrollY < window.innerHeight - this.fixedHeader.height()) {
			this.$el.addModifier('transparent')
		} else {
			this.$el.removeModifier('transparent')
		}
	}
}
