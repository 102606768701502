import $ from 'jquery'
import Block from '../block'

export default class ProductInfo extends Block {
	static title = 'product-info'

	constructor($el) {
		super($el)

		this.dataType = 'product'
		this.countInput = this.$el.find('input[name="count"]')

		// store the current selection and other relevant information in FormData
		this.data = new FormData(this.$el.get(0))
		this.data.append('ajax', true)

		// the color block has changed its active color, update the product info
		$(document).on('color:change', (event, data) =>
			this.changeColor(event, data),
		)

		// the size block has changed its active size, update the product info
		$(document).on('size:change', (event, data) => this.changeSize(event, data))

		this.$el.submit((event) => this.handleSubmit(event))

		$(document).trigger('product:view-item')
	}

	changeColor(event, data) {
		this.$el.findElement('color-name').text(data.name)
		this.data.set('color_id', data.id)
	}

	changeSize(event, data) {
		this.$el.findElement('size-name').text(data.name)
		this.data.set('size_id', data.id)
	}

	setLoading(loading) {
		if (loading) {
			this.$el.find('.button').addModifier('loading')
		} else {
			this.$el.find('.button').removeModifier('loading')
		}
	}

	handleSubmit(event) {
		event.preventDefault()

		// b2b shops handles shopping cart updates without javascript
		if (this.$el.hasModifier('b2b')) {
			return null
		}

		// color and size are required
		if (
			this.data.has('color_id') === false ||
			this.data.has('size_id') === false
		) {
			$(document).trigger('message:error', this.$el.data('validationLabel'))
			return null
		}

		if (this.countInput) {
			this.data.append('count', this.countInput.val())
		}

		this.setLoading(true)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					$(document).trigger('product:add', json)
					$(document).trigger('shoppingcart:add-to-cart', this.dataType)
					$('.nav__item[data-shoppingcart] .nav__count').text(json.count)
				} else {
					$(document).trigger('message:error', json.error)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setLoading(false))
	}
}
