import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class SizeSelect extends Block {
	static title = 'size-select'

	constructor($el) {
		super($el)

		this.dropdown = this.$el.findElement('dropdown')
		this.stockIndicator = this.$el.findElement('stock')

		if (!this.$el.hasClass('size-select--hidden')) {
			this.handleChange({
				target: {
					value: this.dropdown.find(':selected').val(),
					checked: false,
				},
			})
		}

		// if an user selects a new color, we want to display the corresponding size select
		$(document).on('color:change', (event, data) =>
			this.handleColorChange(event, data),
		)

		this.dropdown.change((event) => this.handleChange(event))

		if (!this.$el.hasModifier('hidden')) {
			this.handleStockIndicatorChange()
		}
	}

	handleColorChange(event, data) {
		if (data.id == this.$el.data('colorId')) {
			this.$el.removeModifier('hidden')
			this.dropdown.prop('disabled', false)
		} else {
			this.$el.addModifier('hidden')
			this.dropdown.prop('disabled', true)
		}

		if (!this.$el.hasModifier('hidden')) {
			this.handleChange(event)
		}
	}

	handleChange(event) {
		const selected = this.dropdown.find(':selected')
		$(document).trigger('size:change', {
			id: event.target.value ?? selected.val(),
			name: selected.data('title'),
			price: selected.data('price'),
			advice: selected.data('priceAdvice'),
			stock: selected.data('stock'),
			remove: !event.target.checked,
		})

		this.handleStockIndicatorChange()
	}

	handleStockIndicatorChange() {
		const stockValue = this.dropdown.find(':selected').data('stock')
		if (stockValue > 0) {
			const stockText = this.stockIndicator.data('inStock')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: true,
			})
		} else {
			const stockText = this.stockIndicator.data('soldOut')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: false,
			})
		}
	}
}
