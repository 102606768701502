import Block from '../block'

export default class Tabs extends Block {
	static title = 'tabs'

	constructor($el) {
		super($el)

		$(document).on('hashchange', () => this.updateActive())

		this.updateActive()
	}

	updateActive() {
		if (window.location.hash.length > 1) {
			this.$el.findElement('tab').removeModifier('active')
			const tab = this.$el
				.find(`.tabs__tab[href="${window.location.hash}"]`)
				.addModifier('active')
			this.$el
				.findElement('content')
				.removeModifier('active')
				.eq(tab.index())
				.addModifier('active')
		}
	}
}
