import Block from '../../common.blocks/block'

export default class Pagination extends Block {
	static title = 'pagination'

	constructor($el) {
		super($el)

		this.page = 1
		this.loading = false

		this.contentQuery = this.$el.data('contentQuery')
		this.startPageParams = new URLSearchParams(window.location.search)

		if (
			!$(this.contentQuerySelector).length ||
			(this.startPageParams.has('ipage') &&
				this.page != this.startPageParams.get('ipage'))
		) {
			this.fetchNextPage()
		} else {
			this.registerLoadMore()
		}
	}

	registerLoadMore() {
		this.$loadMore = this.$el.findElement('link')
		this.$loadMore.on('click', (e) => {
			e.preventDefault()
			this.fetchNextPage()
		})
	}

	fetchNextPage() {
		this.loading = true
		this.$el.findElement('loader').addModifier('active')
		let searchParams = new URLSearchParams(window.location.search)

		const currentPage = this.page
		this.page = this.page + 1

		$.get(this.url, (html) => {
			const $html = $('<div>', { html })

			const content = $html.find(this.contentQuerySelector)
			$(this.contentQuery).append(content)

			const paginationContainer = $html.find(`.${Pagination.title}__container`)
			this.$el.findElement('container').html(paginationContainer)

			this.$el.findElement('loader').removeModifier('active')
			this.loading = false

			if (
				searchParams.has('ipage') &&
				currentPage != searchParams.get('ipage') &&
				!this.$el.findElement('link').hasModifier('disabled')
			) {
				this.fetchNextPage()
				return
			} else {
				if (
					content.length &&
					!this.$el.findElement('link').hasModifier('disabled')
				) {
					this.registerLoadMore()
				}
			}

			window.history.replaceState(
				null,
				'',
				window.location.origin +
					window.location.pathname +
					'?ipage=' +
					this.page,
			)
		})
	}

	get url() {
		const params = new URLSearchParams()
		params.set('page', this.page)

		const url = new URL(window.location.href)
		url.search = window.location.search + '&' + params.toString()

		return url.toString()
	}

	get contentQuerySelector() {
		return `${this.contentQuery} > *`
	}
}
