import BaseAddToWishlist from '../../common.blocks/add-to-wishlist'

export default class AddToWishlist extends BaseAddToWishlist {
	static title = 'wishlist'

	constructor($el) {
		super($el)

		const default_size_id = this.$el.data('default-size-id')
		const default_color_id = this.$el.data('default-color-id')

		if (default_size_id) {
			this.data.set('size_id', default_size_id)
		}
		if (default_color_id) {
			this.data.set('color_id', default_color_id)
		}
	}
}
