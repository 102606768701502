import $ from 'jquery'

import Block from '../block'

export default class NumberInput extends Block {
	static title = 'number'

	constructor($el) {
		super($el)

		this.min = Number(this.$el.findElement('input').attr('min') ?? 0)
		this.max = Number(this.$el.findElement('input').attr('max') ?? Infinity)

		if (this.$el.hasModifier('product')) {
			$(document).on('size:change', (event, data) => {
				if (data.stock >= 1) {
					this.$el.findElement('input').val(1)
					this.max = Number(data.stock)
					this.$el.removeModifier('disabled')
				} else {
					this.$el.addModifier('disabled')
				}
			})
		}

		const self = this
		this.$el.findElement('button').click(function () {
			self.handleButtonClick($(this))
		})
	}

	handleButtonClick($button) {
		let value = Number(this.$el.findElement('input').val())

		if ($button.hasModifier('plus')) {
			value = Math.min(this.max, value + 1)
		} else {
			value = Math.max(this.min, value - 1)
		}

		this.$el.findElement('input').val(value).trigger('change')
	}
}
