import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class ShoppingcartAddress extends Block {
	static title = 'shoppingcart-address'

	constructor($el) {
		super($el)

		this.faultyZipcodes = [
			2221, 2222, 2223, 2224, 2225, 2231, 2232, 2235, 2236, 2241, 2242, 2243,
			2244, 2245, 2251, 2252, 2253, 2254, 2261, 2263, 2264, 2265, 2266, 2267,
			2271, 2272, 2273, 2274, 2275, 2281, 2282, 2283, 2284, 2285, 2286, 2287,
			2288, 2289, 2291, 2292, 2295, 2311, 2312, 2313, 2314, 2315, 2316, 2317,
			2318, 2321, 2322, 2323, 2324, 2331, 2332, 2333, 2334, 2341, 2342, 2343,
			2351, 2352, 2353, 2362, 2381, 2382, 2394, 2491, 2492, 2493, 2495, 2496,
			2497, 2498, 2511, 2512, 2513, 2514, 2515, 2516, 2517, 2518, 2521, 2522,
			2523, 2524, 2525, 2526, 2531, 2532, 2533, 2541, 2542, 2543, 2544, 2545,
			2546, 2547, 2548, 2551, 2552, 2553, 2554, 2555, 2561, 2562, 2563, 2564,
			2565, 2566, 2571, 2572, 2573, 2574, 2581, 2582, 2583, 2584, 2585, 2586,
			2587, 2591, 2592, 2593, 2594, 2595, 2596, 2597, 2611, 2612, 2613, 2614,
			2616, 2622, 2623, 2624, 2625, 2626, 2627, 2628, 2629, 2631, 2632, 2635,
			2636, 2641, 2642, 2643, 2645, 2651, 2652, 2661, 2662, 2665, 2675, 2685,
			2711, 2712, 2713, 2715, 2716, 2717, 2718, 2719, 2721, 2722, 2723, 2724,
			2725, 2726, 2727, 2728, 2729, 2731, 2735, 2751, 2752,
		]

		this.checkbox = this.$el.find('input[type="checkbox"]')
		this.mdc = this.$el.find('.mdc-select--invoice-address')
		this.input = this.$el.find('select[name="invoiceaddress_id"]')
		this.address = this.$el.find('select[name="address_id"]')
		this.envMessage = this.$el.find('.shoppingcart-address__env-message')

		this.checkbox.on('change', () => {
			if (this.checkbox.is(':checked')) {
				this.mdc.addClass('mdc-select--disabled')
				this.input.prop('disabled', true)
				this.input.val(this.address.val()).change()
			} else {
				this.mdc.removeClass('mdc-select--disabled')
				this.input.prop('disabled', false)
			}
		})

		this.environmentCheck()
		this.address.on('change', () => {
			this.environmentCheck()
		})
	}

	environmentCheck() {
		if (
			$.inArray(
				Number(
					this.address.find('option:selected').data('zipcode').slice(0, -2),
				),
				this.faultyZipcodes,
			) > -1
		) {
			this.envMessage.removeClass('shoppingcart-address__env-message--hidden')
		} else {
			this.envMessage.addClass('shoppingcart-address__env-message--hidden')
		}
	}
}
