import $ from 'jquery'

import Block from '../block'

export default class ShoppingcartTable extends Block {
	static title = 'shoppingcart-table'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('cart')) {
			this.rows = this.$el.findElement('row')

			this.rows.each((index, element) => {
				this.deleteButton = $(element).find('.shoppingcart-table__delete')
				this.deleteButton.on('click', () => {
					this.removeItem($(element).attr('data-product-id'))
				})

				$(element)
					.find('.number__button--min')
					.on('click', () => {
						$(document).trigger('shoppingcart:event', {
							event: 'remove_from_cart',
							item_id: $(element).data('productItemId'),
							item_name: $(element).data('productItemName'),
							item_brand: $(element).data('productItemBrand'),
							item_category: $(element).data('productItemCategory'),
							item_variant: $(element).data('productItemVariant'),
							price: $(element).data('productPrice'),
							quantity: 1,
						})
					})

				$(element)
					.find('.number__button--plus')
					.on('click', () => {
						$(document).trigger('shoppingcart:event', {
							event: 'add_to_cart',
							item_id: $(element).data('productItemId'),
							item_name: $(element).data('productItemName'),
							item_brand: $(element).data('productItemBrand'),
							item_category: $(element).data('productItemCategory'),
							item_variant: $(element).data('productItemVariant'),
							price: $(element).data('productPrice'),
							quantity: 1,
						})
					})
			})

			$(document).trigger('shoppingcart:view-cart')
		}
	}

	removeItem(id) {
		const url = '/shoppingcart/delete/' + id

		$(document).trigger('shoppingcart:remove-from-cart', id)

		$.ajax({
			url: url,
			method: 'DELETE',
			success: function () {
				window.location.reload()
			},
			error: function () {
				//
			},
		})
	}
}
