import $ from 'jquery'

import CommonProductInfo from '../../common.blocks/product-info'

export default class ProductInfo extends CommonProductInfo {
	static title = 'product-info'

	constructor($el) {
		super($el)

		this.price = this.$el.findElement('price__highlight')
		this.advice = this.$el.findElement('price__advice')
		this.selectedSize = this.$el.findElement('option--size')
		this.selectedColor = this.$el.findElement('option--color')

		this.submitButton = $(this.$el).find('button[type="submit"]')

		// if an user selects a new color, we want to display the corresponding size select
		$(document).on('size:change', (event, data) =>
			this.handleSizeChange(event, data),
		)

		$(document).on('color:change', (event, data) =>
			this.handleColorChange(event, data),
		)
	}

	handleSizeChange(event, data) {
		const title = this.selectedSize.data('title')
		this.selectedSize.text(title + ': ' + (data.name ?? 'N/A'))
		this.price.text('€' + data.price)
		this.handleNoValue(data)

		if (data.advice > data.price) {
			this.advice.text('€' + data.advice)
			this.advice.css('display', 'block')
		} else {
			this.advice.css('display', 'none')
			this.price.addClass('product-info__price__highlight--solo')
		}

		$('input[name="ga4-size"]').val(data.id)
	}

	handleColorChange(event, data) {
		const title = this.selectedColor.data('title')
		this.selectedColor.text(title + ': ' + data.name)
		this.price.text(data.price)
		this.handleNoValue(data)

		$('input[name="ga4-color"]').val(data.id)
	}

	handleNoValue(data) {
		const disabled = !data.stock
		this.submitButton.attr('disabled', disabled)
		if (this.submitButton.get(0)) {
			this.submitButton.get(0).lastChild.nodeValue =
				' ' +
				(disabled
					? this.submitButton.data('oos-text')
					: this.submitButton.data('add-text'))
		}
	}
}
