import $ from 'jquery'

import Block from '../block'

export default class Popup extends Block {
	static title = 'popup'

	constructor($el) {
		super($el)

		this.$el.findElement('close').on('click', () => {
			this.close()
		})
	}

	open() {
		this.$el.removeModifier('hidden')
		$(document).trigger('popup:open')
	}

	close() {
		this.$el.addModifier('hidden')
		$(document).trigger('popup:close')
	}
}
